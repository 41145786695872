export const getPrompt = (topic, language) => {
  return {
    label: 'Free Style',
    topic: `Free creation`,
    description: `Fun facts short-videos have a great engagement potential because they offer a quick and easy way to learn something new and interesting. They can be used to educate and entertain at the same time, making them perfect for social media platforms and other digital channels. They can be used to showcase a wide range of topics, such as science, history, culture, and more, making them suitable for a wide range of audiences. Whether you're looking to increase your brand's reach or simply want to create engaging content, fun facts short-videos are a great choice.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676748234/Woxo/Idea2video/category_details/fun_facts/fun_fact_3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676748234/Woxo/Idea2video/category_details/fun_facts/fun_fact_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676748234/Woxo/Idea2video/category_details/fun_facts/fun_fact_2.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@DUK',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676906348/Woxo/Idea2video/category_details/fun_facts/mYawsnvMl1Rre0R350tsjger3TAQ4yi1i4hCTOBEzDvdgr9ggSRNPY2iU50sW5VD78yR3VInNw_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Did You Know ?',
        channel: '@DUK',
        subscribers: '2.27M',
        description: `A channel that provides interesting and surprising facts about a variety of topics, including science, history, and nature.`
      },
      {
        url: 'https://www.youtube.com/@AsapSCIENCE',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676906384/Woxo/Idea2video/category_details/fun_facts/AL5GRJU1smsnzPU533Gu1vekyh-uHcLt75Dh67ZuswiRew_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'AsapSCIENCE',
        channel: '@AsapSCIENCE',
        subscribers: '10.3M',
        description: `A channel that explores a wide range of scientific topics, including what would happen if certain elements or phenomena disappeared.`
      },
      {
        url: 'https://www.youtube.com/@GeographyNow',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676906404/Woxo/Idea2video/category_details/fun_facts/AL5GRJX_5YLUUMGZxFBs0cNyue3zSSTu4MvkdMgQAfYdJg_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Geography Now',
        channel: '@GeographyNow',
        subscribers: '3.08M',
        description: `A channel that provides fun and informative videos about different countries, including their history, culture, and geography.`
      }
    ],
    tier: 'free',
    promptContent: `
    User Prompt: ${topic}
    Create a short video script of 3 scenes, following these guidelines:
    Scene 1: Start with an engaging hook (up to 20 words)
    Scene 2: Continue the story, developing interest and intrigue (up to 20 words)
    Scene 3: Conclude the video with a strong call to action to keep viewers engaged with your content (up to 20 words)
    Ensure each voiceover is under 20 words (Language: ${language}).`
  };
};

export const getPrompt = (topic, language) => {
  return {
    label: 'How to',
    topic: `How to "${topic}"`,
    description: `"How-to" short-videos are a great way to provide step-by-step instructions on a variety of topics, from cooking and crafting to home repairs and DIY projects. They offer a practical and hands-on approach to learning, allowing viewers to follow along and try the task themselves. They can be used to teach a wide range of skills, from basic to advanced, making them suitable for a wide range of audiences, from beginners to experts. Whether you're looking to teach a specific skill or just want to share your own personal tips and tricks, "How-to" short-videos are a great choice. They are perfect for social media, websites, and other digital channels, as they can be easily shared and viewed on a variety of devices.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676945253/Woxo/Idea2video/category_details/how_to/how_to_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676945253/Woxo/Idea2video/category_details/how_to/how_to_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676945253/Woxo/Idea2video/category_details/how_to/how_to_3.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@HowToCookThat',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676944608/Woxo/Idea2video/category_details/how_to/AL5GRJU-cYORq3Zumhx1cylgY6KgVX2NMjUZ5pNizxPfeg_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'How To Cook That ',
        channel: '@HowToCookThat',
        subscribers: '4.92M',
        description: `A channel that provides step-by-step tutorials for creating unique and delicious desserts.`
      },
      {
        url: 'https://www.youtube.com/@5MinuteCraftsFAMILY',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676944626/Woxo/Idea2video/category_details/how_to/1riWCu3OdjGFdUOtlB5d0xkQgQ1SjZKgx7629L85HmccWqvmfJkR_6lF1IpmeBiAOsuFe6jpaA_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: '5-Minute Crafts FAMILY',
        channel: '@5MinuteCraftsFAMILY',
        subscribers: '15.4M',
        description: `A channel that give you fun DIY projects, crafts and life hacks for the whole family. Experience the joy of doing it yourself!`
      },
      {
        url: 'https://www.youtube.com/@TheKingofRandom',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676944661/Woxo/Idea2video/category_details/how_to/1T067AXy174-ahl8b3xyMBRJn1k2b9HGmxzjP8842SiLjtULbYWl-0KEieRDdEnzEA359kAE_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'TKOR',
        channel: '@TheKingofRandom',
        subscribers: '12.5M',
        description: `A channel that provides tutorials on a wide range of topics, including science experiments, life hacks, and DIY projects.`
      }
    ],
    tier: 'premium',
    promptContent: `
    User prompt: ${topic}
    Create a short video script of 5 scenes providing step-by-step instructions on how to do something, following these guidelines:
    Scene 1: Start with an engaging hook (up to 20 words)
    Scene 2: Present the first step or instruction on how to do the specific task (up to 20 words).
    Scene 3: Continue with the next step or instruction, building upon the previous one (up to 20 words).
    Scene 4: Provide the following step or instruction, guiding the viewers further in accomplishing the task (up to 20 words).
    Scene 5: Conclude the video with a strong call to action to keep viewers engaged with your content, such as "Follow us for more helpful guides on how to master!"
    Ensure each voiceover is under 20 words (Language: ${language}).`
  };
};

export const getPrompt = () => {
  // README: This prompt assumes that the voiceover property comes with all the separators, so before
  // passed to the video and the voiceover API the characters should be removed
  return {
    promptContent: `
    Act as a senior video storyteller. Help to create a condensed short video script to drive engagement. Engaging visuals and interesting facts are key, along with a strong call to action to keep viewers engaged with your content. You must produce your instructions in a JSON format compatible with the WOXO app.
    Use between 3 and up to 5 scenes, each scene containing a ‘voiceover’ and an ‘image-prompt’ properties.
    The ‘voiceover’ is divided into phrases or keywords demarcated by the ‘~’ symbol. Significant phrases or keywords must be emphasized with asterisks, such as “word.” Example: “voiceover”: “Did you *know* ~ that ~ the first *programming language* ~ was ~ created in *1883?*” Ensure each voiceover is under 20 words.
    The ‘image-prompt’ will receive a prompt to generate a compelling image for every scene with stable diffusion.
    Feel free to end with a call to action focused on engagement.
    A JSON array of video scripts may include one or multiple scripts, as shown below: [
      {
        “musicCategory”: “[Select a suitable Music Category from: Christmas, Funny, Epic, Bright, Happy, Inspirational, Romantic, Angry, Calm, Dark, Dramatic, Sad, Hip Hop]“,
        “scenes”: [
          {
            “voiceover”: “*Keyword* ~ Phrase”, “image-prompt”: “create a prompt to generate an image for this scene”
          },
          {
            “voiceover”: “Phrase ~ *Keyword*“, “image-prompt”: “create a prompt to generate an image for this scene”
          },
          {
            “voiceover”: “*Keyword* ~ Phrase”, “image-prompt”: “create a prompt to generate an image for this scene”
          }
          // Aim to limit scenes to fewer than five
        ],
        “meta”: {
          “title”: "Craft a compelling, SEO-friendly video title. It should hint at the content's surprising nature, sparking curiosity and intrigue. Remember to encapsulate the video's essence in a few words while creating suspense, similar to 'The Fastest Animal Isn't What You Think'. Your title should be so tantalizing that viewers can't resist clicking.",
          “description”: “A brief, YouTube-friendly video description”,
          “hashtags”: “Video-related hashtags to boost up your views, separated by spaces: #hashtag1 #hashtag2”,
          “mediaDescription”: “Create a narrative describing an image that depicts the main subject of the video (If the main subject is a person a portrait). This image should fit the video’s theme. (Always in English)”
        }
      }
    ]`
  };
};

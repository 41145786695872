export const getPrompt = (topic, language) => {
  return {
    label: 'Hidden Secrets',
    topic: `Hidden Secrets about "${topic}"`,
    description: `"Hidden Secrets" short-videos offer a captivating and mysterious way to share little-known information and stories. They can be used to uncover secrets about historical events, reveal behind-the-scenes information about popular locations or landmarks, or even share insider knowledge about a particular industry or profession. These videos provide a sense of exclusivity and intrigue, making them perfect for audiences who are curious and love to learn new things. They can be used to entertain, educate, and even inspire people to explore and discover more about the world around them. Whether you're a history buff looking to share your knowledge or a brand looking to create engaging and informative content, "Hidden Secrets" short-videos are a great choice.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676944232/Woxo/Idea2video/category_details/hidden_secrets/hidden_secrets_3.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676944232/Woxo/Idea2video/category_details/hidden_secrets/hidden_secrets_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676944232/Woxo/Idea2video/category_details/hidden_secrets/hidden_secrets_1.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@Insider',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676942499/Woxo/Idea2video/category_details/hidden_secrets/AL5GRJXTDp5bQq9Ij2fCi6voobIStU9mkFrVBpgl6VP-Qg_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Insider',
        channel: '@Insider',
        subscribers: '7.97M',
        description: `A channel that provides in-depth reporting on a variety of topics, including secret locations, hidden talents, and untold stories.`
      },
      {
        url: 'https://www.youtube.com/@Top5Unknowns',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676942532/Woxo/Idea2video/category_details/hidden_secrets/AL5GRJWeiThs6zwOwEpnYqPkVlnvzx9Xyp1kbL1sJxHEVg_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Top 5 Unknowns',
        channel: '@Top5Unknowns',
        subscribers: '2.07M',
        description: `A channel that explores lesser-known facts and secrets about a variety of topics, including history, science, and nature.`
      },
      {
        url: 'https://www.youtube.com/@mindboggler',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676942577/Woxo/Idea2video/category_details/hidden_secrets/AL5GRJVZZHacveALkkQsIOoc2GRSRS0NUg0uG6aCWwJSUQ_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Mind Boggler',
        channel: '@mindboggler',
        subscribers: '2.96M',
        description: `A channel that explores a variety of topics, including hidden talents, secret locations, and mysterious phenomena.`
      }
    ],
    tier: 'premium',
    promptContent: `
    User prompt: ${topic}
    Create a short video script of 5 scenes uncovering hidden secrets, following these guidelines:
    Scene 1: Start with an engaging hook (up to 20 words)
    Scene 2: Revealing a secret (up to 20 words).
    Scene 3: More evidence from Scene 2 (up to 20 words).
    Scene 4: Deepen the intrigue by divulging another secret from Scene 3 (up to 20 words).
    Scene 5: Conclude the video with a strong call to action to keep viewers engaged with your content, such as "Follow us for more secrets!"
    Ensure each voiceover is under 20 words (Language: ${language}).`
  };
};

export const getPrompt = (topic, language) => {
  return {
    label: 'Quotes',
    topic: `Quotes about about "${topic}"`,
    description: `"Quotes" short-videos are a great way to inspire, motivate and uplift people. They can take the form of inspiring words from famous leaders, authors, and thinkers, or even everyday people. They can be used to provide a sense of perspective and wisdom, and can be enjoyed by audiences of all ages. They can be used to showcase a wide range of topics, from love and friendship to success and motivation. Whether you're looking to share your own personal quotes or a brand looking to create engaging and positive content, "Quotes" short-videos are a great choice. They are perfect for social media, websites, and other digital channels, as they can be easily shared and viewed on a variety of devices and can be a great source of inspiration for the audience.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676952519/Woxo/Idea2video/category_details/quotes/quotes_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676952519/Woxo/Idea2video/category_details/quotes/quotes_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676952519/Woxo/Idea2video/category_details/quotes/quotes_3.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@Motivation2Study',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676950297/Woxo/Idea2video/category_details/quotes/AL5GRJU17lxSWc_vtzK2rBbHlxQper3mR6YjuxkVQZhIaA_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Motivation2Study',
        channel: '@Motivation2Study',
        subscribers: '3.97M',
        description: ` A channel that provides motivational quotes and study tips for students.`
      },
      {
        url: 'https://www.youtube.com/@RedFrostMotivation',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676950322/Woxo/Idea2video/category_details/quotes/sx2UTbpCoLz6v3SwIs_dKSRclgfP3V4KcPNEGjXgijfF18lx-UG8xW_9kOoi-Fzntcw_OGtjng_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'RedFrost Motivation',
        channel: '@RedFrostMotivation',
        subscribers: '1.8M',
        description: `A channel that inspire viewers by bringing new life to some of the greatest, most empowering texts of history.`
      },
      {
        url: 'https://www.youtube.com/@theschooloflifetv',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676950340/Woxo/Idea2video/category_details/quotes/AL5GRJWmcEwf9CpwGkiRVwHyOnF_ic-oFO7zqFdtnymAFg_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'The School of Life',
        channel: '@theschooloflifetv',
        subscribers: '8.26M',
        description: `A channel that provides quotes and insights on philosophy, psychology, and personal development.`
      }
    ],
    tier: 'free',
    promptContent: `
    User prompt: ${topic}
    Create a short video script of 5 scenes featuring a quote, following these guidelines:
    Scene 1: Start with an engaging hook (up to 20 words)
    Scene 2: Share the quote (up to 20 words).
    Scene 3: Start discussing the significance of the quote from Scene 2 (up to 20 words).
    Scene 4: Continue discussing the significance of the quote from Scene 3 (up to 20 words).
    Scene 5: Conclude the video with a strong call to action to keep viewers engaged with your content, such as "Follow us for more quotes!"
    Ensure each voiceover is under 20 words (Language: ${language}).`
  };
};

export const getPrompt = (topic, language) => {
  return {
    label: 'FAQ',
    topic: `FAQ about "${topic}"`,
    description: `"FAQs" short-videos are a great way to provide answers to common questions and concerns on a specific topic or industry. They can be used to address customer inquiries, provide information on a product or service, or simply clear up any confusion or misunderstandings. They can be used to educate and inform the audience and provide them with the information they need to make informed decisions. They can be used to showcase a wide range of topics, from technical information to customer service. Whether you're a business looking to provide customer support or a brand looking to create engaging and informative content, "FAQs" short-videos are a great choice. They are perfect for social media, websites, and other digital channels, as they can be easily shared and viewed on a variety of devices.`,
    examples: [
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676956527/Woxo/Idea2video/category_details/faq/faq_1.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676956527/Woxo/Idea2video/category_details/faq/faq_2.mp4',
      'https://res.cloudinary.com/dakp804eh/video/upload/v1676956527/Woxo/Idea2video/category_details/faq/faq_3.mp4'
    ],
    inspiration: [
      {
        url: 'https://www.youtube.com/@Techquickie',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676954955/Woxo/Idea2video/category_details/faq/AL5GRJUfvT92-VX9c6JGalyj4aYSt_M6Rv1GY38XVSJHnA_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Techquickie',
        channel: '@Techquickie',
        subscribers: '4.19M',
        description: `A channel that provides answers to frequently asked questions about technology and electronics.`
      },
      {
        url: 'https://www.youtube.com/@Scienceabc',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676954978/Woxo/Idea2video/category_details/faq/AL5GRJXgS8t753saKftv_o75g4Yh8bcCjsNWt-S2clOW2g_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Science ABC',
        channel: '@Scienceabc',
        subscribers: '300K',
        description: `A channel that uses animation and simple language to explain common scientific concepts and answer frequently asked questions.`
      },
      {
        url: 'https://www.youtube.com/@Lifehacker',
        channelLogo:
          'https://res.cloudinary.com/dakp804eh/image/upload/v1676954992/Woxo/Idea2video/category_details/faq/AL5GRJUcWjrFyzQToaTC1bC5ddFLUy6Kekb4m-9_fMOW-g_s176-c-k-c0x00ffffff-no-rj.jpg',
        chanelName: 'Lifehacker',
        channel: '@Lifehacker',
        subscribers: '471K',
        description: `A channel that provides answers to frequently asked questions about productivity, personal finance, and other life skills.`
      }
    ],
    tier: 'premium',
    promptContent: `
    User prompt: ${topic}
    Create a short video script of 5 scenes addressing a frequently asked question (FAQ), following these guidelines:
    Scene 1: Begin with a hook presenting a common question about the main subject (up to 20 words)
    Scene 2: Provide the answer to the question from Scene 1 (up to 20 words).
    Scene 3: Provide evidence for the answer from Scene 2 (up to 20 words).
    Scene 4: Expand or give additional context to the answer from Scene 3 (up to 20 words).
    Scene 5: Conclude the video with a strong call to action to keep viewers engaged with your content, such as "Follow us for more FAQs!"
    Ensure each voiceover is under 20 words (Language: ${language}).`
  };
};
